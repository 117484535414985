import React, { useImperativeHandle, memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const UserLanguage = React.forwardRef((props, ref) => {
	const { t } = useTranslation();

	useImperativeHandle(ref, () => ({
		//
	}));

	return (
		<Fragment>
			<h4>{t('Deputy.Yourlanguagepreference')}</h4>

			<div className="boxLangs">
				<span
					className={props.activeClass === 'en' ? 'active' : ''}
					onClick={() => props.changelang('en')}
				>
					English
				</span>
				<span
					className={props.activeClass === 'de' ? 'active' : ''}
					onClick={() => props.changelang('de')}
				>
					Deutsch
				</span>
				<span
					className={props.activeClass === 'fr' ? 'active' : ''}
					onClick={() => props.changelang('fr')}
				>
					Français
				</span>
				<span
					className={props.activeClass === 'es' ? 'active' : ''}
					onClick={() => props.changelang('es')}
				>
					Español
				</span>
				<span
					className={props.activeClass === 'pt' ? 'active' : ''}
					onClick={() => props.changelang('pt')}
				>
					Português
				</span>
				<span
					className={props.activeClass === 'zh' ? 'active' : ''}
					onClick={() => props.changelang('zh')}
				>
					简体中文
				</span>
			</div>
		</Fragment>
	);
});

export default memo(UserLanguage);
