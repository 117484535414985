import React, { Fragment, useEffect, useRef } from 'react';
import IMAGlandingBig from '../../assets/images/adminlanding_bg.png';
import axios from '../../axios-custom';
import { useHistory } from 'react-router-dom';
import './index.scss';
import store from '../../state/store';
import { useReactive, useMemoizedFn } from 'ahooks';
import '../../../node_modules/antd/lib/modal/style/index.css';
import { useMount } from 'ahooks';
import ICONradioOFF from '../../assets/icons/toggle-inactiv.svg';
import ICONradioON from '../../assets/icons/toggle-activ.svg';
import InputFileUpload from '../../components/InputFileUploadImage/InputFileUploadImage';
import SliderPopup from './SliderPopup';
const { useModel } = store;

const AdminCarousel = ({ bgHandler, isUserLogin }) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;
	const history = useHistory();

	let fileUploadRef = useRef(null);
	let sliderPopupRef = useRef(null);

	const userForm = useReactive({
		id: '',
		isShown: 0,
	});

	useMount(async () => {
		window.scrollTo(0, 0);
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			localStorage.removeItem('NCM_Token');
			localStorage.removeItem('NCM_User');
			localStorage.removeItem('NCM_User_bak');
			localStorage.removeItem('NCM_AuthorizationTime');
			localStorage.removeItem('NCM_landing_popup');
			localStorage.removeItem('NCM_searchval');
			localStorage.removeItem('NCM_filterval');
			localStorage.removeItem('NCM_expandedAll');
			localStorage.removeItem('NCM_scrollY');
			localStorage.removeItem('NCM_lang_bak');
			history.replace('/');
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 6 * 60 * 60) {
				localStorage.removeItem('NCM_Token');
				localStorage.removeItem('NCM_User');
				localStorage.removeItem('NCM_User_bak');
				localStorage.removeItem('NCM_AuthorizationTime');
				localStorage.removeItem('NCM_landing_popup');
				localStorage.removeItem('NCM_searchval');
				localStorage.removeItem('NCM_filterval');
				localStorage.removeItem('NCM_expandedAll');
				localStorage.removeItem('NCM_scrollY');
				localStorage.removeItem('NCM_lang_bak');
				history.replace('/');
				setTimeout(() => {
					window.location.reload();
				}, 10);
				return;
			}
		}
		//
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		let email = '';
		if (userBak && JSON.stringify(userBak) !== '{}') {
			email = userBak.email;
		} else {
			email = user.email;
		}
		//
		toggleLoading();
		let admin = await axios.post('/feedbackServices/apiUser/getUserInfo', {
			email: email,
		});
		toggleLoading();
		if (admin && admin.data && admin.data.isAdmin === 'true') {
			//
		} else {
			history.replace('/landing');
			setTimeout(() => {
				window.location.reload();
			}, 10);
		}
	});

	useMount(async () => {
		toggleLoading();
		let data = await axios.post('/feedbackServices/welcome/display', {
			id: '0d15984b-b2ea-4390-94e8-bc43036b7da1',
		});
		userForm.id = data.data.id;
		userForm.isShown = data.data.shown ? 1 : 0;
		toggleLoading();
	});

	useEffect(() => {
		bgHandler(true, false, IMAGlandingBig, false);
	}, [bgHandler]);

	const changeExpandedAll = useMemoizedFn(() => {
		if (userForm.isShown === 1) {
			userForm.isShown = 0;
		} else {
			userForm.isShown = 1;
		}
	});

	const openModal = () => {
		if (fileUploadRef.current.itattachments.length) {
			sliderPopupRef.current.showModel(fileUploadRef.current.itattachments);
		}
	};

	const submit = async () => {
		const obj = {
			id: userForm.id,
			isShown: userForm.isShown,
		};
		const response = await axios.post(`/feedbackServices/welcome/display`, obj);
		let notificationObj = {
			type: 'success',
			title: 'Success',
			description: `You have successfully update the carousel.`,
			duration: 5,
		};
		toastMessageShow(notificationObj);
	};

	return (
		<Fragment>
			<section className="admincarousel">
				<SliderPopup ref={sliderPopupRef} />
				<div className="maintitle">Carousel Management</div>
				<div style={{ marginTop: '20px' }}>
					<InputFileUpload ref={fileUploadRef} />
				</div>
				<div>
					<span className="deactivewrapa">
						{userForm.isShown === 1 ? (
							<span className="deactivewrapatitle">Active</span>
						) : (
							<span className="deactivewrapatitle">Deactivate</span>
						)}
						<button
							className="btnAllExpand"
							onClick={() => changeExpandedAll()}
						>
							{userForm.isShown === 1 ? (
								<img alt="Shrinked" src={ICONradioON} />
							) : (
								<img alt="Expanded" src={ICONradioOFF} />
							)}
						</button>
					</span>
				</div>
				<div className="buttonfunction">
					<span className="button">
						<button className="btnCancel" onClick={() => openModal()}>
							Preview
						</button>
						<button className="btnSend" onClick={() => submit()}>
							Submit
						</button>
					</span>
				</div>
			</section>
		</Fragment>
	);
};

export default AdminCarousel;
