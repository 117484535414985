import React, { useState, useImperativeHandle } from 'react';
import { Modal } from 'antd';
import '../../../../node_modules/antd/lib/modal/style/index.css';
import { useReactive, useMemoizedFn } from 'ahooks';
import axios from '../../../axios-custom';
import store from '../../../state/store';
const { useModel } = store;

const titleModal = React.forwardRef((props, ref) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;
	const [isModalVisible, setIsModalVisible] = useState(false);

	useImperativeHandle(ref, () => ({
		openModel: () => {
			setIsModalVisible(true);
			titleData.sectionen = '';
			titleData.sectionde = '';
			titleData.sectionfr = '';
			titleData.sectiones = '';
			titleData.sectionpt = '';
		},
	}));

	const titleData = useReactive({
		sectionen: '',
		sectionde: '',
		sectionfr: '',
		sectiones: '',
		sectionpt: '',
	});

	// value
	const inputChangeTitleHandler = useMemoizedFn((e, val) => {
		e.target.classList.remove('gotError');
		e.target.classList.add('isFilled');
		//
		if (e.target.value === '') {
			e.target.classList.remove('gotError');
			e.target.classList.remove('isFilled');
		}
		//
		titleData[val] = e.target.value;
	});

	const reset = () => {
		titleData.sectionen = '';
		titleData.sectionde = '';
		titleData.sectionfr = '';
		titleData.sectiones = '';
		titleData.sectionpt = '';
	};

	const saveTitle = async () => {
		let flag = false;
		let temp = JSON.parse(JSON.stringify(titleData));
		if (temp.sectionen === '') {
			document.querySelector('.sectionen').classList.remove('isFilled');
			document.querySelector('.sectionen').classList.add('gotError');
			flag = true;
		}
		if (flag) {
			return;
		}
		toggleLoading();
		await axios.post('/feedbackServices/saction/saveSec', temp);
		toggleLoading();
		setIsModalVisible(false);
		props.fetchAllData();
		props.resetType();
	};

	return (
		<Modal
			open={isModalVisible}
			className="titleModal"
			footer={null}
			closable={false}
			getContainer={false}
			style={{ top: 150 }}
		>
			<div className="title">Add Section</div>
			<div className="inputwrap">
				<textarea
					className="txaFeedback m34 sectionen"
					value={titleData.sectionen}
					onChange={(e) => inputChangeTitleHandler(e, 'sectionen')}
				/>
				<span className="editbutton">English</span>
			</div>
			<div className="inputwrap">
				<textarea
					className="txaFeedback m34 sectionde"
					value={titleData.sectionde}
					onChange={(e) => inputChangeTitleHandler(e, 'sectionde')}
				/>
				<span className="editbutton">Deutsch</span>
			</div>
			<div className="inputwrap">
				<textarea
					className="txaFeedback m34 sectionfr"
					value={titleData.sectionfr}
					onChange={(e) => inputChangeTitleHandler(e, 'sectionfr')}
				/>
				<span className="editbutton">Français</span>
			</div>
			<div className="inputwrap">
				<textarea
					className="txaFeedback m34 sectiones"
					value={titleData.sectiones}
					onChange={(e) => inputChangeTitleHandler(e, 'sectiones')}
				/>
				<span className="editbutton">Español</span>
			</div>
			<div className="inputwrap">
				<textarea
					className="txaFeedback m34 sectionpt"
					value={titleData.sectionpt}
					onChange={(e) => inputChangeTitleHandler(e, 'sectionpt')}
				/>
				<span className="editbutton">Português</span>
			</div>
			<div className="addbuttongroup">
				<button className="btnSend" onClick={() => saveTitle()}>
					Save
				</button>
				<button
					className="btnCancel"
					style={{ marginLeft: '30px' }}
					onClick={() => setIsModalVisible(false)}
				>
					Close
				</button>
				<button className="btnCancel" onClick={() => reset()}>
					Reset
				</button>
			</div>
		</Modal>
	);
});

export default titleModal;
