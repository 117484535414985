import React, { useState, useImperativeHandle, useRef } from 'react';
import { Modal } from 'antd';
import '../../../../node_modules/antd/lib/modal/style/index.css';
import { useReactive, useMemoizedFn } from 'ahooks';
import RichEditor from './RichEditor';
import DropdownList from 'react-dropdown';
import ICONup from '../../../assets/icons/arrow-up.svg';
import ICONdownBlue from '../../../assets/icons/arrowdownblue.svg';
import InputFileUpload from '../../InputFileUploadImage/InputFileUploadImage';
import axios from '../../../axios-custom';
import store from '../../../state/store';
const { useModel } = store;

const QuestionModal = React.forwardRef((props, ref) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [dropData, setDropData] = useState([]);

	let editorRef1 = useRef(null);
	let editorRef2 = useRef(null);
	let editorRef3 = useRef(null);
	let editorRef4 = useRef(null);
	let editorRef5 = useRef(null);
	let fileUploadRef = useRef(null);

	useImperativeHandle(ref, () => ({
		openModel: (treeData) => {
			setIsModalVisible(true);
			let temp = treeData.map((item) => {
				let obj = {
					value: item.key,
					label: item.title,
				};
				return obj;
			});
			setDropData(temp);
			//
			questionData.answeren = '';
			questionData.answerde = '';
			questionData.answerfr = '';
			questionData.answeres = '';
			questionData.answerpt = '';
			questionData.sid = '';
			//
			questionData.questionen = '';
			questionData.questionde = '';
			questionData.questionfr = '';
			questionData.questiones = '';
			questionData.questionpt = '';
			//
			setTimeout(() => {
				editorRef1.current.setHtml('');
				editorRef2.current.setHtml('');
				editorRef3.current.setHtml('');
				editorRef4.current.setHtml('');
				editorRef5.current.setHtml('');
				fileUploadRef.current.clearAll();
			}, 10);
		},
	}));

	const questionData = useReactive({
		answeren: '',
		questionen: '',
		answerde: '',
		questionde: '',
		answerfr: '',
		questionfr: '',
		answeres: '',
		questiones: '',
		answerpt: '',
		questionpt: '',
		sid: '',
	});

	const inputChangeAnswerHandler = useMemoizedFn((e, val) => {
		e.target.classList.remove('gotError');
		e.target.classList.add('isFilled');
		//
		if (e.target.value === '') {
			e.target.classList.remove('gotError');
			e.target.classList.remove('isFilled');
		}
		//
		questionData[val] = e.target.value;
	});

	const mainCauseHandler = useMemoizedFn((e) => {
		questionData.sid = e.value;
		document.querySelector('.ddlFeedbackNofilter').classList.remove('gotError');
		document.querySelector('.ddlFeedbackNofilter').classList.add('isFilled');
	});

	const saveTitle = async () => {
		let flag = false;
		questionData.answeren = editorRef1.current.getHtml();
		questionData.answerde = editorRef2.current.getHtml();
		questionData.answerfr = editorRef3.current.getHtml();
		questionData.answeres = editorRef4.current.getHtml();
		questionData.answerpt = editorRef5.current.getHtml();
		let temp = JSON.parse(JSON.stringify(questionData));
		//
		if (temp.questionen === '') {
			document.querySelector('.questionen').classList.remove('isFilled');
			document.querySelector('.questionen').classList.add('gotError');
			flag = true;
		}
		if (temp.sid === '') {
			document
				.querySelector('.ddlFeedbackNofilter')
				.classList.remove('isFilled');
			document.querySelector('.ddlFeedbackNofilter').classList.add('gotError');
			flag = true;
		}
		//
		if (fileUploadRef.current && fileUploadRef.current.itattachments.length) {
			temp.ansImageEncoded = fileUploadRef.current.itattachments[0].content;
			temp.ansImageName = fileUploadRef.current.itattachments[0].filename;
		} else {
			temp.ansImageEncoded = '';
			temp.ansImageName = '';
		}
		//
		let obj = {
			sectionId: questionData.sid.split('---')[0],
			list: [temp],
		};
		if (flag) {
			return;
		}
		toggleLoading();
		await axios.post('/feedbackServices/faqMgmt/saveFaq', obj);
		toggleLoading();
		setIsModalVisible(false);
		props.fetchAllData();
		props.resetType();
	};

	const reset = () => {
		questionData.answeren = '';
		questionData.answerde = '';
		questionData.answerfr = '';
		questionData.answeres = '';
		questionData.answerpt = '';
		questionData.sid = '';
		//
		questionData.questionen = '';
		questionData.questionde = '';
		questionData.questionfr = '';
		questionData.questiones = '';
		questionData.questionpt = '';
		//
		editorRef1.current.setHtml('');
		editorRef2.current.setHtml('');
		editorRef3.current.setHtml('');
		editorRef4.current.setHtml('');
		editorRef5.current.setHtml('');
	};

	return (
		<Modal
			open={isModalVisible}
			className="titleModal"
			footer={null}
			closable={false}
			getContainer={false}
			style={{ top: 150 }}
		>
			<div className="title">Add question</div>
			<div className="inputwrap">
				<span className="editbutton">English</span>
				<textarea
					className="txaFeedback m34 questionen"
					value={questionData.questionen}
					onChange={(e) => inputChangeAnswerHandler(e, 'questionen')}
				></textarea>
				<RichEditor value={questionData.answeren} ref={editorRef1} />
			</div>
			<div className="inputwrap">
				<span className="editbutton">Deutsch</span>
				<textarea
					className="txaFeedback m34 questionde"
					value={questionData.questionde}
					onChange={(e) => inputChangeAnswerHandler(e, 'questionde')}
				></textarea>
				<RichEditor value={questionData.answerde} ref={editorRef2} />
			</div>
			<div className="inputwrap">
				<span className="editbutton">Français</span>
				<textarea
					className="txaFeedback m34 questionfr"
					value={questionData.questionfr}
					onChange={(e) => inputChangeAnswerHandler(e, 'questionfr')}
				></textarea>
				<RichEditor value={questionData.answerfr} ref={editorRef3} />
			</div>
			<div className="inputwrap">
				<span className="editbutton">Español</span>
				<textarea
					className="txaFeedback m34 questiones"
					value={questionData.questiones}
					onChange={(e) => inputChangeAnswerHandler(e, 'questiones')}
				></textarea>
				<RichEditor value={questionData.answeres} ref={editorRef4} />
			</div>
			<div className="inputwrap">
				<span className="editbutton">Português</span>
				<textarea
					className="txaFeedback m34 questionpt"
					value={questionData.questionpt}
					onChange={(e) => inputChangeAnswerHandler(e, 'questionpt')}
				></textarea>
				<RichEditor value={questionData.answerpt} ref={editorRef5} />
			</div>
			<div className="inputwrap">
				<DropdownList
					options={dropData}
					placeholder="select section"
					className="ddlFeedbackNofilter"
					menuClassName="options"
					onChange={(event) => mainCauseHandler(event)}
					controlClassName="lineControl"
					arrowClosed={
						<img className="iconDown" alt="Expand" src={ICONdownBlue} />
					}
					arrowOpen={<img className="iconUp" alt="Expand" src={ICONup} />}
					value={questionData.sid}
				/>
			</div>
			<div style={{ marginTop: '20px' }}>
				<InputFileUpload ref={fileUploadRef} />
			</div>
			<div className="addbuttongroup">
				<button className="btnSend" onClick={() => saveTitle()}>
					Save
				</button>
				<button
					className="btnCancel"
					style={{ marginLeft: '30px' }}
					onClick={() => setIsModalVisible(false)}
				>
					Close
				</button>
				<button className="btnCancel" onClick={() => reset()}>
					Reset
				</button>
			</div>
		</Modal>
	);
});

export default QuestionModal;
