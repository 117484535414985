import React, {
	useImperativeHandle,
	memo,
	Fragment,
	useState,
	useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import { useReactive, useMemoizedFn, useBoolean } from 'ahooks';
import store from '../../../state/store';
// import ICONerror from '../../../assets/icons/error.svg';
import ICONinfo from '../../../assets/icons/info-i.svg';
import axios from '../../../axios-custom';
const { useModel } = store;

const FlyoutDeputyEdit = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const [startDateData, setStartDateData] = useState(
		props.deputyDetail.fromdate
			? props.moment(props.deputyDetail.fromdate)
			: null
	);
	const [endDateData, setEndDateData] = useState(
		props.deputyDetail.todate ? props.moment(props.deputyDetail.todate) : null
	);
	// eslint-disable-next-line no-unused-vars
	const [tempState, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;
	const [state, { setTrue, setFalse }] = useBoolean(false);

	useImperativeHandle(ref, () => ({
		//
	}));

	const titleCase = useMemoizedFn((str) => {
		if (str === '') {
			return '';
		} else {
			let arr = str.toLowerCase().split(' ');
			for (let i = 0; i < arr.length; i++) {
				if (arr[i] !== '') {
					arr[i] = arr[i][0].toUpperCase() + arr[i].substring(1, arr[i].length);
				} else {
					continue;
				}
			}
			return arr.join(' ');
		}
	});

	useEffect(() => {
		setStartDateData(props.moment(props.deputyDetail.fromdate));
		setEndDateData(props.moment(props.deputyDetail.todate));
		editForm.imfromdate = props
			.moment(props.deputyDetail.fromdate)
			.format('YYYY-MM-DD');
		editForm.imtodate = props
			.moment(props.deputyDetail.todate)
			.format('YYYY-MM-DD');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const editForm = useReactive({
		imfromdate: '',
		imtodate: '',
	});

	const startDatePickerOnChange = useMemoizedFn((date) => {
		setStartDateData(date);
		setFalse();
		editForm.imfromdate = props.moment(date).format('YYYY-MM-DD');
		//
		setEndDateData(null);
		editForm.imtodate = '';
	});

	const endDatePickerOnChange = useMemoizedFn((date) => {
		setEndDateData(date);
		setFalse();
		editForm.imtodate = props.moment(date).format('YYYY-MM-DD');
	});

	const startDisabledDate = useMemoizedFn((current) => {
		return (
			// (current && current < props.moment().subtract(1, 'day').endOf('day')) ||
			// current > props.moment().add(2, 'year').subtract(1, 'day')
			current <= props.moment().subtract(1, 'day').endOf('day') ||
			current >= props.moment().add(2, 'year')
		);
	});

	const endDisabledDate = useMemoizedFn((current) => {
		if (startDateData) {
			let diff = props
				.moment(props.moment(startDateData).format('YYYY-MM-DD'))
				.diff(props.moment(props.moment().format('YYYY-MM-DD')), 'second');
			if (diff <= 0) {
				return (
					(current &&
						current < props.moment().subtract(1, 'day').endOf('day')) ||
					current > props.moment().add(2, 'year')
				);
			} else {
				return (
					current <=
						props.moment(startDateData).subtract(1, 'day').endOf('day') ||
					current >= props.moment().add(2, 'year')
				);
			}
		} else {
			return (
				(current && current < props.moment().subtract(1, 'day').endOf('day')) ||
				current > props.moment().add(2, 'year')
			);
		}
	});

	const inputError = useMemoizedFn((type, num) => {
		document.querySelector(`.${type}`).classList.add('gotError');
		document.querySelector(`.${type}`).classList.remove('isFilled');
		const listItem = document.querySelector(`.${type}`).parentNode;
		const error = listItem.getElementsByTagName('span')[num];
		if (error) {
			error.classList.add('visible');
		}
	});

	const addDeputy = useMemoizedFn(async () => {
		let flag = false;
		let editFormTemp = JSON.parse(JSON.stringify(editForm));
		// imfromdate
		if (editFormTemp.imfromdate === '') {
			inputError('imfromdate', 0);
			flag = true;
			setTrue();
		}
		// imtodate
		if (editFormTemp.imtodate === '') {
			inputError('imtodate', 0);
			flag = true;
			setTrue();
		}
		if (flag) {
			return;
		}
		let loggedinId = '';
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		if (userBak && JSON.stringify(userBak) !== '{}') {
			loggedinId = userBak.email;
		} else {
			loggedinId = user.email;
		}
		let imlangu = '';
		if (localStorage.getItem('NCM_lang') === 'en') {
			imlangu = 'E';
		} else if (localStorage.getItem('NCM_lang') === 'de') {
			imlangu = 'D';
		} else if (localStorage.getItem('NCM_lang') === 'fr') {
			imlangu = 'F';
		} else if (localStorage.getItem('NCM_lang') === 'es') {
			imlangu = 'S';
		} else if (localStorage.getItem('NCM_lang') === 'pt') {
			imlangu = 'P';
		} else if (localStorage.getItem('NCM_lang') === 'zh') {
			imlangu = 'E';
		} else {
			imlangu = 'E';
		}
		let postobj = {
			imdeputy: props.deputyDetail.deputy.trim(),
			imfromdate: editFormTemp.imfromdate.trim(),
			immainpartner: props.deputyDetail.mainpartner.trim(),
			imop: 'E',
			imtodate: editFormTemp.imtodate.trim(),
			fname: props.deputyDetail.fname.trim(),
			lname: props.deputyDetail.lname.trim(),
			loggedinId: loggedinId.trim(),
			lang: localStorage.getItem('NCM_lang')
				? localStorage.getItem('NCM_lang')
				: 'en',
			imlangu: imlangu,
			// targetSystem: props.deputyDetail.targetSystem,
			targetSystem: 'COBALT,EC',
		};
		toggleLoading();
		const response = await axios.post(
			'/feedbackServices/portalSap/maintainDeputy',
			postobj
		);
		toggleLoading();
		if (response.data.exsubrc === 0) {
			let notificationObj = {
				type: 'success',
				title: t('Detail.submitSuccess1'),
				description: `${t(
					'Deputy.editsuccess1'
				)} ${postobj.imdeputy.toLowerCase()} ${t('Deputy.editsuccess2')}`,
				duration: 5,
			};
			toastMessageShow(notificationObj);
			props.handleDeputy('', {});
			props.fetchDeputy();
		} else {
			// exmessage
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description: response.data.exmessage,
				duration: 0,
			};
			toastMessageShow(notificationObj);
		}
	});

	return (
		<Fragment>
			<h4>{t('Deputy.Changetimeperiod')}</h4>
			{state ? (
				<div className="errorMessage">
					<span className="img">
						<img className="ICONinfo" alt="Error" src={ICONinfo} />;
					</span>
					<span className="title">{t('Support.Pleasefill')}</span>
				</div>
			) : (
				<Fragment />
			)}
			<ul className="lstDeputyInfo">
				<li className="long">
					<span className="tag">{t('Deputy.Firstname')}</span>
					<span className="value">{titleCase(props.deputyDetail.fname)}</span>
				</li>
				<li className="long">
					<span className="tag">{t('Deputy.Lastname')}</span>
					<span className="value">{titleCase(props.deputyDetail.lname)}</span>
				</li>
				<li className="long">
					<span className="tag">{t('Deputy.User')}</span>
					<span className="value">
						{props.deputyDetail.deputy.toLowerCase()}
					</span>
				</li>
				<li id="from">
					<span className="tag">{t('Deputy.From')}</span>
					<DatePicker
						locale={props.locale}
						disabledDate={startDisabledDate}
						suffixIcon={<></>}
						allowClear={false}
						className="dtpDeputy imfromdate"
						onChange={(date) => startDatePickerOnChange(date)}
						placeholder={t('Detail.SelectDate')}
						format="YYYY-MM-DD"
						value={startDateData}
						showToday={false}
						getPopupContainer={() => document.getElementById('from')}
						inputReadOnly
					/>
				</li>
				<li id="to">
					<span className="tag">{t('Deputy.Until')}</span>
					<DatePicker
						locale={props.locale}
						disabledDate={endDisabledDate}
						suffixIcon={<></>}
						allowClear={false}
						className="dtpDeputy imtodate"
						onChange={(date) => endDatePickerOnChange(date)}
						placeholder={t('Detail.SelectDate')}
						format="YYYY-MM-DD"
						value={endDateData}
						showToday={false}
						getPopupContainer={() => document.getElementById('to')}
						inputReadOnly
						disabled={startDateData === null}
					/>
				</li>
			</ul>
			<div className="boxActions">
				<button
					className="btnCancel"
					onClick={() => props.handleDeputy('', {})}
				>
					{t('Detail.Cancel')}
				</button>
				<button className="btnApply" onClick={() => addDeputy()}>
					{t('Deputy.ApplyChanges')}
				</button>
			</div>
		</Fragment>
	);
});

export default memo(FlyoutDeputyEdit);
