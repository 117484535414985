import React, { useEffect, Fragment } from 'react';
import ICONdown from '../../assets/icons/arrow-down.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
// import ICONlanguages from '../../assets/icons/languages.svg';
// import ICONuser from '../../assets/icons/user.svg';
// import ICONdeputyman from '../../assets/icons/deputy-manage.svg';
import { useTranslation } from 'react-i18next';
import './FAQ.scss';
import _ from 'lodash';
import axios from '../../axios-custom';
import { useReactive, useMemoizedFn } from 'ahooks';
import store from '../../state/store';
import { getBase64Type } from '../../utils/util';
const { useModel } = store;

const FAQTemp = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;
	//
	const { t } = useTranslation();
	const delay = 155;

	useEffect(() => {
		fetchData();
		//
		async function fetchData() {
			toggleLoading();
			const response = await axios.get('/feedbackServices/faqMgmt/getAllFaq');
			toggleLoading();
			//
			let tempall = [];
			for (let i = 0; i < response.data.length; i++) {
				let obj = response.data[i];
				obj.sectionid = response.data[i].section.id;
				obj.sectionIndex = response.data[i].sectionIndex;
				tempall.push(obj);
			}
			//
			let tempfaq = [];
			for (const value of Object.entries(_.groupBy(tempall, 'sectionid'))) {
				let obj = {};
				//
				let lang = `section${localStorage.getItem('NCM_lang')}`;
				if (lang === 'sectionzh') {
					lang = 'sectionen';
				}
				//
				if (value[1][0].section[lang] === '') {
					obj.section = value[1][0].section.sectionen;
				} else {
					obj.section = value[1][0].section[lang];
				}
				obj.sectionIndex = value[1][0].sectionIndex;
				// obj.section = value[1][0].section['sectionen'];
				obj.question = [];
				if (value[1].length === 1 && value[1][0].faqid === null) {
					tempfaq.push(obj);
					continue;
				} else {
					for (let i = 0; i < value[1].length; i++) {
						//
						let anslang = `answer${localStorage.getItem('NCM_lang')}`;
						if (anslang === 'answerzh') {
							anslang = 'answeren';
						}
						let queslang = `question${localStorage.getItem('NCM_lang')}`;
						if (queslang === 'questionzh') {
							queslang = 'questionen';
						}
						//
						let questiondataen = {};
						//
						if (value[1][i].ansImageEncoded && value[1][i].ansImageName) {
							let temp = value[1][i].ansImageName.split('.');
							let file = temp[temp.length - 1].toLowerCase();
							let mime = getBase64Type(file);
							//
							questiondataen.ansImageEncoded = `${mime}${value[1][i].ansImageEncoded}`;
							questiondataen.ansImageName = value[1][i].ansImageName;
						} else {
							questiondataen.ansImageEncoded = '';
							questiondataen.ansImageName = '';
						}
						//
						if (
							value[1][i][anslang] === '' ||
							value[1][i][anslang] === '<p><br></p>'
						) {
							questiondataen.answer = value[1][i].answeren;
						} else {
							questiondataen.answer = value[1][i][anslang];
						}
						//
						if (
							`${value[1][i][queslang]}` === '<p><br></p>' ||
							`${value[1][i][queslang]}` === ''
						) {
							questiondataen.question = `${value[1][i].questionen}`;
						} else {
							questiondataen.question = `${value[1][i][queslang]}`;
						}
						// questiondataen.question = `${value[1][i]['questionen']}`;
						questiondataen.questionIndex = value[1][i].questionIndex;
						obj.question.push(questiondataen);
					}
					tempfaq.push(obj);
				}
			}
			tempfaq.sort(compareSectionIndex('sectionIndex'));
			//
			for (let i = 0; i < tempfaq.length; i++) {
				if (tempfaq[i].question.length) {
					tempfaq[i].question.sort(compareAQIndex('questionIndex'));
				}
			}
			//
			FAQ.FAQData = tempfaq;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		props.bgHandler(true, false, '', false);
	}, [props]);

	const closeChildren = useMemoizedFn((elem) => {
		if (elem.querySelector('.faq-lst-item.expanded')) {
			elem.querySelector('.faq-lst-item.expanded').classList.remove('expanded');
		}
	});

	const compareSectionIndex = (propertyName) => {
		return function (obj1, obj2) {
			let value1 = obj1[propertyName];
			let value2 = obj2[propertyName];
			if (value1 < value2) {
				return -1;
			} else if (value1 > value2) {
				return 1;
			} else {
				return 0;
			}
		};
	};

	const compareAQIndex = (propertyName) => {
		return function (obj1, obj2) {
			let value1 = obj1[propertyName];
			let value2 = obj2[propertyName];
			if (value1 < value2) {
				return -1;
			} else if (value1 > value2) {
				return 1;
			} else {
				return 0;
			}
		};
	};

	const FAQ = useReactive({
		// FAQData: [
		// 	{
		// 		section: 'aaaa',
		// 		question: [
		// 			{
		// 				question: 'ssss',
		// 				answer: '<p>ssss</p>',
		// 			},
		// 			{
		// 				question: 'ssss',
		// 				answer: '<p>ssss</p>',
		// 			},
		// 		],
		// 	},
		// ],
		FAQData: [],
	});

	const closeSiblings = useMemoizedFn((elem) => {
		// Setup siblings array and get the first sibling
		// var siblings = [];
		var sibling = elem.parentNode.firstChild;
		// Loop through each sibling and push to the array
		while (sibling) {
			if (
				sibling.nodeType === 1 &&
				sibling !== elem &&
				sibling.classList.contains('expanded')
			) {
				// siblings.push(sibling);
				const thisSibling = sibling;

				setTimeout(() => {
					thisSibling.classList.remove('expanded');

					// if there is a sublist (on main categories), close it
					if (thisSibling.childNodes[1].querySelector('.faq-lst')) {
						closeChildren(thisSibling.childNodes[1].querySelector('.faq-lst'));
					}
				}, delay); // using timeout here for a bit better user experience
			}
			sibling = sibling.nextSibling;
		}
	});

	const toggleElement = useMemoizedFn((event) => {
		const listItem = event.target.closest('.faq-lst-item');
		closeSiblings(listItem);
		// const bttnItem = event.target.closest('.btnExpand');

		if (listItem.classList.contains('expanded')) {
			listItem.classList.remove('expanded');

			setTimeout(() => {
				// if there is a sublist (on main categories), close it
				if (listItem.childNodes[1].querySelector('.faq-lst')) {
					closeChildren(listItem.childNodes[1].querySelector('.faq-lst'));
				}
			}, delay); // using timeout here for a bit better user experience
		} else {
			listItem.classList.add('expanded');
			// bttnItem.classList.add('expanded');
		}
	});

	return (
		<section className="faq">
			<div className="inner-text">
				<h1 className="ui-main-heading">{t('FAQ.title')}</h1>
				<h2 className="ui-sub-heading has-color">{t('FAQ.intro1')}</h2>
				<p className="ui-copy-text">
					{t('FAQ.intro3-1')}
					<a href="/support" className="ui-link">
						{t('FAQ.intro3-2')}
					</a>
				</p>
				<div className="faq-content-area">
					<ul className="faq-lst">
						{FAQ.FAQData.map((item, index) => {
							return (
								<li
									key={index}
									className={
										props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
									}
								>
									<div
										className="faq-lst-item-panel"
										onClick={(e) => toggleElement(e)}
									>
										<h4 className="faq-lst-item-headline">{item.section}</h4>
										<button className="btnExpand">
											<img
												className="accordion-icon expand"
												alt="Expand"
												src={ICONdown}
											/>
											<img
												className="accordion-icon shrink"
												alt="Shrink"
												src={ICONup}
											/>
										</button>
									</div>
									<div className="faq-lst-item-content">
										<ul className="faq-lst">
											{item.question.map((subitem, subindex) => {
												return (
													<li
														key={subindex}
														className={
															props.expandedAll
																? 'faq-lst-item expanded'
																: 'faq-lst-item'
														}
													>
														<div
															className="faq-lst-item-panel"
															onClick={(e) => toggleElement(e)}
														>
															<h4 className="faq-lst-item-headline">
																{subitem.question}
															</h4>
															<button className="btnExpand">
																<img
																	className="accordion-icon expand"
																	alt="Expand"
																	src={ICONdown}
																/>
																<img
																	className="accordion-icon shrink"
																	alt="Shrink"
																	src={ICONup}
																/>
															</button>
														</div>
														<div className="faq-lst-item-content">
															<div
																className="aaa"
																dangerouslySetInnerHTML={{
																	__html: subitem.answer,
																}}
															></div>
															{subitem.ansImageEncoded !== '' ? (
																<div className="answerimage">
																	<img
																		src={subitem.ansImageEncoded}
																		alt={subitem.ansImageName}
																	/>
																</div>
															) : (
																<Fragment />
															)}
														</div>
													</li>
												);
											})}
										</ul>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default FAQTemp;
