import React, { Fragment, useEffect, useState } from 'react';
import axios from '../../axios-custom';
import ICONback from '../../assets/icons/arrow-left.svg';
import IMAGregisterBig from '../../assets/images/register_big.jpg';
import { Tooltip } from 'antd';
import '../../../node_modules/antd/lib/tooltip/style/index.css';
import './Register.scss';
import { notificationShowHtml } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ICONinfo from '../../assets/icons/info-i.svg';
import store from '../../state/store';
const { useModel } = store;

const Register = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow, toastMessageShowHTML } = dispatchers;
	const { t } = useTranslation();
	const history = useHistory();

	const [userCreds, setUserCreds] = useState({
		txb_first_name: '',
		txb_last_name: '',
		txb_email_address: '',
	});
	const [mb50, setMb50] = useState(false);

	useEffect(() => {
		localStorage.removeItem('NCM_Token');
		localStorage.removeItem('NCM_User');
		localStorage.removeItem('NCM_User_bak');
		localStorage.removeItem('NCM_AuthorizationTime');
		localStorage.removeItem('NCM_landing_popup');
		localStorage.removeItem('NCM_searchval');
		localStorage.removeItem('NCM_filterval');
		localStorage.removeItem('NCM_expandedAll');
		localStorage.removeItem('NCM_scrollY');
		localStorage.removeItem('NCM_lockId');
		localStorage.removeItem('NCM_lang_bak');
		// if (
		// 	localStorage.getItem('NCM_Token') &&
		// 	localStorage.getItem('NCM_Token') !== ''
		// ) {
		// 	history.replace('/landing');
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		props.bgHandler(false, false, IMAGregisterBig, true);
	}, [props]);

	// WIP: Generalized Input Handler
	const inputChangeHandler = (e) => {
		e.target.classList.remove('gotError');
		e.target.parentNode.parentNode
			.getElementsByTagName('span')[3]
			.classList.remove('visible');
		e.target.parentNode.parentNode
			.getElementsByTagName('span')[4]
			.classList.remove('visible');
		document.querySelector('.tooltip_first_name').classList.remove('visible');
		document.querySelector('.tooltip_last_name').classList.remove('visible');
		document.querySelector('.tooltip_email').classList.remove('visible');

		setUserCreds((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}));

		// Will be generalized
		// if (e.target.value !== '') {
		// 	e.target.classList.add("isFilled");
		// } else {
		// 	e.target.classList.remove("isFilled");
		// }
	};

	const registerUser = async () => {
		// const account = '3INCMFEE';
		// const password = 'Hs-CV3gt*.$6';
		// const encodedString =
		// 	'Basic ' + Buffer.from(`${account}:${password}`).toString('base64');
		let txb_first_name = userCreds.txb_first_name.trim();
		let txb_last_name = userCreds.txb_last_name.trim();
		let txb_email_address = userCreds.txb_email_address.trim();
		//
		if (txb_first_name === '') {
			document.getElementById('txb_first_name').classList.add('gotError');
			const listItem =
				document.getElementById('txb_first_name').parentNode.parentNode;
			const error = listItem.getElementsByTagName('span')[3];
			error.classList.add('visible');
			return;
		}
		if (!/^[a-zA-Z][-a-zA-Z]+$/.test(txb_first_name)) {
			document.getElementById('txb_first_name').classList.add('gotError');
			const listItem =
				document.getElementById('txb_first_name').parentNode.parentNode;
			const error = listItem.getElementsByTagName('span')[4];
			error.classList.add('visible');
			document.querySelector('.tooltip_first_name').classList.add('visible');
			return;
		}

		if (txb_last_name === '') {
			document.getElementById('txb_last_name').classList.add('gotError');
			const listItem =
				document.getElementById('txb_last_name').parentNode.parentNode;
			const error = listItem.getElementsByTagName('span')[3];
			error.classList.add('visible');
			return;
		}
		if (!/^[A-Za-z]+$/.test(txb_last_name)) {
			document.getElementById('txb_last_name').classList.add('gotError');
			const listItem =
				document.getElementById('txb_last_name').parentNode.parentNode;
			const error = listItem.getElementsByTagName('span')[4];
			error.classList.add('visible');
			document.querySelector('.tooltip_last_name').classList.add('visible');
			return;
		}
		if (txb_email_address === '') {
			document.getElementById('txb_email_address').classList.add('gotError');
			const listItem =
				document.getElementById('txb_email_address').parentNode.parentNode;
			const error = listItem.getElementsByTagName('span')[3];
			error.classList.add('visible');
			setMb50(true);
			return;
		}
		if (
			// eslint-disable-next-line no-useless-escape
			!/^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,100})$/.test(
				txb_email_address
			)
		) {
			document.getElementById('txb_email_address').classList.add('gotError');
			const listItem =
				document.getElementById('txb_email_address').parentNode.parentNode;
			const error = listItem.getElementsByTagName('span')[4];
			error.classList.add('visible');
			document.querySelector('.tooltip_email').classList.add('visible');
			setMb50(false);
			return;
		}
		if (txb_email_address.toUpperCase().includes('@BASF.COM')) {
			document.getElementById('txb_email_address').classList.add('gotError');
			const listItem =
				document.getElementById('txb_email_address').parentNode.parentNode;
			const error = listItem.getElementsByTagName('span')[5];
			error.classList.add('visible');
			setMb50(true);
			return;
		}

		toggleLoading();

		let dev = {
			create: 'E1EB05BC-AF4C-469A-848D-31CC07CD8C42',
		};

		let qual = {
			create: 'E1EB05BC-AF4C-469A-848D-31CC07CD8C42',
		};

		let prod = {
			create: 'E1EB05BC-AF4C-469A-848D-31CC07CD8C42',
		};

		let config = {};

		if (window.location.origin.includes('localhost')) {
			config = dev;
		} else if (window.location.origin.includes('dev')) {
			config = prod;
		} else if (window.location.origin.includes('qual')) {
			config = qual;
		} else {
			config = prod;
		}
		//
		let responseMail = await axios.post(
			'/feedbackServices/authorize/checkAuth',
			{
				email: txb_email_address,
				targetSystem: 'COBALT,EC',
			}
		);
		//
		if (responseMail.data.excode === '00') {
			// Duplicate Check
			let duplicateResponse = await axios.post(
				'/feedbackServices/3iam/duplicateCheck',
				{ SV_MX_MAIL_PRIMARY: txb_email_address }
			);
			//
			if (!duplicateResponse.data || !duplicateResponse.data.items) {
				notificationShowHtml(
					'error',
					'500 Internal Server Error',
					'',
					0,
					'Error.500'
				);
				toggleLoading();
				return;
			}
			//
			if (
				duplicateResponse.data.items.length &&
				duplicateResponse.data.items[0]['basf_iden_3iam_status'] === 'active'
			) {
				// Search by one MSKEY
				let mskey = duplicateResponse.data.items[0]['mskey'];
				let searchResponse = await axios.post('/feedbackServices/3iam/info', {
					ID: Number.parseInt(mskey),
				});
				if (searchResponse.data.d['SV_BASF_IDEN_SUBSCRIPTION_NCM_FEEDBACK']) {
					toggleLoading();
					let notificationObj = {
						type: 'error',
						title: t('Register.NotificationAlreadyRegistered1'),
						description: t('Register.NotificationAlreadyRegistered2'),
						duration: 0,
					};
					history.push('/');
					toastMessageShow(notificationObj);
				} else {
					// link account
					let linkObj = {
						ID: Number.parseInt(mskey),
						SV_BASF_IDEN_SUBSCRIPTION_NCM_FEEDBACK: true,
					};
					let linkResponse = await axios.post(
						'/feedbackServices/3iam/link',
						linkObj
					);
					if (linkResponse.data === 'Link Successful') {
						toggleLoading();
						let notificationObj = {
							type: 'success',
							title: t('Register.NotificationAlreadyRegistered3'),
							description: `<div>${t(
								'Register.NotificationAlreadyRegistered4A'
							)}</div><div style="height: 10px"></div><div><i>${t(
								'Register.NotificationAlreadyRegistered4B'
							)}</i></div>`,
							duration: 0,
						};
						history.push('/');
						toastMessageShowHTML(notificationObj);
					} else {
						toggleLoading();
						let notificationObj = {
							type: 'error',
							title: 'Link Failed',
							description: 'Link Failed',
							duration: 0,
						};
						history.push('/');
						toastMessageShow(notificationObj);
					}
				}
			} else {
				// 3IAM TOKEN
				const payload = {
					TASK_GUID: config.create,
					SV_MX_FIRSTNAME: txb_first_name,
					SV_MX_LASTNAME: txb_last_name,
					SV_MX_MAIL_PRIMARY: txb_email_address,
					MV_MX_MAIL_ADDITIONAL: [
						{ OPERATION: 'ADD', VALUE: txb_email_address },
					],
					SV_MX_LANGUAGE: 'en',
					targetSystem: 'COBALT,EC',
				};
				let response3iam = await axios.post(
					'/feedbackServices/3iam/register',
					payload
				);
				toggleLoading();
				if (response3iam.data === '04') {
					notificationShowHtml(
						'error',
						t('Register.NotificationNotAllowed1'),
						`${t('Register.NotificationNotAllowed51')}<strong>${t(
							'Register.NotificationNotAllowed52'
						)}</strong>${t(
							'Register.NotificationNotAllowed53'
						)}<span><a href="/support" style='color: #1fa1d2; text-decoration: none;'>${t(
							'Register.NotificationNotAllowed6'
						)}</a></span>.`,
						0,
						'',
						0,
						false
					);
					return;
				}
				if (response3iam.data === 'active') {
					// Active
					let notificationObj = {
						type: 'error',
						title: t('Register.NotificationAlreadyRegistered1'),
						description: t('Register.NotificationAlreadyRegistered2'),
						duration: 0,
					};
					history.push('/');
					toastMessageShow(notificationObj);
				} else if (response3iam.data.includes('verifi')) {
					// Verification
					let notificationObj = {
						type: 'error',
						title: t('Register.ConfirmationRegistrationAlreadyStarted1'),
						description: t('Register.ConfirmationRegistration2'),
						duration: 0,
					};
					history.push('/');
					toastMessageShow(notificationObj);
				} else if (response3iam.data.toLowerCase().includes('deactivate')) {
					// Disabled Deactivated Inactive Deleted
					let notificationObj = {
						type: 'error',
						title: t('Register.NotificationNotAllowed3'),
						description: t('Register.NotificationNotAllowed4'),
						duration: 0,
					};
					history.push('/');
					toastMessageShow(notificationObj);
				} else if (
					response3iam.data.toLowerCase().includes('disable') ||
					response3iam.data.toLowerCase().includes('inactive') ||
					response3iam.data.toLowerCase().includes('delete') ||
					response3iam.data.toLowerCase().includes('failed')
				) {
					notificationShowHtml(
						'error',
						'Error.RegisterFaildtitle',
						'',
						0,
						'Error.RegisterFaild'
					);
					history.push('/');
				} else {
					let notificationObj = {
						type: 'success',
						title: t('Register.ConfirmationRegistration1'),
						description: t('Register.ConfirmationRegistration2'),
						duration: 0,
					};
					history.push('/');
					toastMessageShow(notificationObj);
				}
			}
		} else if (responseMail.data.excode === '04') {
			toggleLoading();
			notificationShowHtml(
				'error',
				t('Register.NotificationNotAllowed1'),
				`${t('Register.NotificationNotAllowed51')}<strong>${t(
					'Register.NotificationNotAllowed52'
				)}</strong>${t(
					'Register.NotificationNotAllowed53'
				)}<span><a href="/support" style='color: #1fa1d2; text-decoration: none;'>${t(
					'Register.NotificationNotAllowed6'
				)}</a></span>.`,
				0,
				'',
				0,
				false
			);
		}
	};

	// MAYBE WILL USE INPUT COMPONENT
	return (
		<Fragment>
			<section className="register">
				<a href="/" className="lnkBack">
					<img alt="Back" src={ICONback} />
					<span>{t('Register.Back')}</span>
				</a>
				<h2>{t('Register.Registration')}</h2>
				<ul className="lstForm">
					<li className="testtest">
						<span className="inputwrap">
							<input
								type="text"
								id="txb_first_name"
								className="txbRegisterItem"
								onChange={(event) => inputChangeHandler(event)}
								placeholder={t('Register.FirstName')}
							/>
							<span className="tooltipwrap">
								<span className="tooltipInput tooltip_first_name">
									<Tooltip
										mouseLeaveDelay={0}
										color="#1fa1d2"
										placement="right"
										title={t('Register.ErrorMesage4')}
									>
										<img alt="iconinfo" className="iconinfo" src={ICONinfo} />
									</Tooltip>
								</span>
							</span>
						</span>
						<span className="errInput">{t('Register.ErrorMesage1')}</span>
						<span className="errInput">{t('Error.validfirstname')}</span>
					</li>
					<li>
						<span className="inputwrap">
							<input
								type="text"
								id="txb_last_name"
								className="txbRegisterItem"
								onChange={(event) => inputChangeHandler(event)}
								placeholder={t('Register.LastName')}
							/>
							<span className="tooltipwrap">
								<span className="tooltipInput tooltip_last_name">
									<Tooltip
										mouseLeaveDelay={0}
										color="#1fa1d2"
										placement="right"
										title={t('Register.ErrorMesage4')}
									>
										<img alt="iconinfo" className="iconinfo" src={ICONinfo} />
									</Tooltip>
								</span>
							</span>
						</span>
						<span className="errInput">{t('Register.ErrorMesage2')}</span>
						<span className="errInput">{t('Error.validlastname')}</span>
					</li>
					<li className={mb50 ? 'mb50' : ''}>
						<span className="inputwrap">
							<input
								type="text"
								id="txb_email_address"
								className="txbRegisterItem"
								onChange={(event) => inputChangeHandler(event)}
								placeholder={t('Register.Email')}
							/>
							<span className="tooltipwrap">
								<span className="tooltipInput tooltip_email">
									<Tooltip
										mouseLeaveDelay={0}
										color="#1fa1d2"
										placement="right"
										title={t('Register.ErrorMesage5')}
									>
										<img alt="iconinfo" className="iconinfo" src={ICONinfo} />
									</Tooltip>
								</span>
							</span>
						</span>
						<span className="errInput">{t('Register.ErrorMesage3')}</span>
						<span className="errInput">{t('Error.validemail')}</span>
						<span className="errInput">{t('Register.Nobasf')}</span>
					</li>
				</ul>
				<button className="btnRegister" onClick={registerUser}>
					{t('Register.CreateAccount')}
				</button>
			</section>
		</Fragment>
	);
};

export default Register;
