import React, { Fragment, useEffect, useRef, useState } from 'react';
import IMAGlandingBig from '../../assets/images/adminlanding_bg.png';
import Changeprofile from '../../assets/icons/changeprofile.svg';
import Contentmanagement from '../../assets/icons/contentmanagement.svg';
import axios from '../../axios-custom';
import { useHistory } from 'react-router-dom';
import './index.scss';
import store from '../../state/store';
import { useReactive } from 'ahooks';
import { Modal } from 'antd';
import '../../../node_modules/antd/lib/modal/style/index.css';
import ICONclose from '../../assets/icons/close.svg';
import ICONsearch from '../../assets/icons/search.svg';
import ICONdownGrey from '../../assets/icons/arrowdowngrey.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import DropdownList from 'react-dropdown';
import ImportModal from './components/ImportModal';
import { useMount } from 'ahooks';
const { useModel } = store;

const AdminHome = ({ bgHandler, isUserLogin }) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;
	const history = useHistory();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [user, setUser] = useState({});
	const [userData, setUserData] = useState([]);
	const [selectClass, setSelectClass] = useState('ddlPeriod');

	const ImportModalRef = useRef(null);

	const userForm = useReactive({
		searchval: '',
		user: '',
	});

	useMount(async () => {
		window.scrollTo(0, 0);
		// let notificationObj = {
		// 	type: 'success',
		// 	title: 'Success',
		// 	description: `You have successfully changed to profile aaa.`,
		// 	duration: 0,
		// };
		// toastMessageShow(notificationObj);
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			localStorage.removeItem('NCM_Token');
			localStorage.removeItem('NCM_User');
			localStorage.removeItem('NCM_User_bak');
			localStorage.removeItem('NCM_AuthorizationTime');
			localStorage.removeItem('NCM_landing_popup');
			localStorage.removeItem('NCM_searchval');
			localStorage.removeItem('NCM_filterval');
			localStorage.removeItem('NCM_expandedAll');
			localStorage.removeItem('NCM_scrollY');
			localStorage.removeItem('NCM_lang_bak');
			history.replace('/');
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 6 * 60 * 60) {
				localStorage.removeItem('NCM_Token');
				localStorage.removeItem('NCM_User');
				localStorage.removeItem('NCM_User_bak');
				localStorage.removeItem('NCM_AuthorizationTime');
				localStorage.removeItem('NCM_landing_popup');
				localStorage.removeItem('NCM_searchval');
				localStorage.removeItem('NCM_filterval');
				localStorage.removeItem('NCM_expandedAll');
				localStorage.removeItem('NCM_scrollY');
				localStorage.removeItem('NCM_lang_bak');
				history.replace('/');
				setTimeout(() => {
					window.location.reload();
				}, 10);
				return;
			}
		}
		//
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		let email = '';
		if (userBak && JSON.stringify(userBak) !== '{}') {
			email = userBak.email;
			setUser(userBak);
		} else {
			email = user.email;
			setUser(user);
		}
		//
		toggleLoading();
		let admin = await axios.post('/feedbackServices/apiUser/getUserInfo', {
			email: email,
		});
		toggleLoading();
		if (admin && admin.data && admin.data.isAdmin === 'true') {
			//
		} else {
			history.replace('/landing');
			setTimeout(() => {
				window.location.reload();
			}, 10);
		}
	});

	useEffect(() => {
		bgHandler(true, false, IMAGlandingBig, false);
	}, [bgHandler]);

	const openModal = () => {
		setIsModalVisible(true);
		userForm.searchval = '';
		setUserData([]);
		setTimeout(() => {
			document.querySelector('.searchval').classList.remove('isFilled');
			document.querySelector('.searchval').classList.remove('gotError');
		}, 10);
	};

	// const openModal2 = () => {
	// 	ImportModalRef.current.openModel();
	// };

	const searchEnter = async (e) => {
		if (e.key === 'Enter') {
			searchUser();
		}
	};

	const searchUser = async () => {
		if (userForm.searchval === '') {
			document.querySelector('.searchval').classList.add('gotError');
			return;
		}
		toggleLoading();
		let user = await axios.post(`/feedbackServices/apiUser/getSearchData`, {
			email: userForm.searchval,
		});
		toggleLoading();
		if (user.data.length === 0) {
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description:
					'Entered user profile is not a registered user of the portal.',
				duration: 5,
			};
			toastMessageShow(notificationObj);
		}
		//
		for (let i = 0; i < user.data.length; i++) {
			user.data[i] = user.data[i].toLowerCase();
		}
		//
		setUserData(user.data);
		userForm.user = '';
		setSelectClass('ddlPeriod');
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const loadinguser = async () => {
		if (userForm.user === '') {
			setSelectClass('ddlPeriod gotError');
			return;
		}
		toggleLoading();
		let userRes = await axios.post('/feedbackServices/apiUser/getUserInfo', {
			email: userForm.user,
		});
		toggleLoading();
		//
		let obj = {
			basfId: userRes.data.id.toLowerCase(),
			email: userRes.data.id.toLowerCase(),
			firstName: userRes.data['first_name'],
			fullName: `${userRes.data['first_name']} ${userRes.data['last_name']}`,
			lastName: userRes.data['last_name'],
		};
		//
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		if (userBak && JSON.stringify(userBak) !== '{}') {
			localStorage.setItem('NCM_User', JSON.stringify(obj));
		} else {
			localStorage.setItem('NCM_User', JSON.stringify(obj));
			localStorage.setItem('NCM_User_bak', JSON.stringify(user));
		}
		//
		let notificationObj = {
			type: 'success',
			title: 'Success',
			description: `You have successfully changed to profile ${userRes.data.id.toLowerCase()}.`,
			duration: 5,
		};
		toastMessageShow(notificationObj);
		setTimeout(() => {
			window.location.reload();
		}, 3000);
	};

	const inputChangeHandler = (e) => {
		e.target.classList.remove('gotError');
		userForm.searchval = e.target.value;
		//
		if (e.target.value !== '') {
			e.target.classList.add('isFilled');
		} else {
			e.target.classList.remove('isFilled');
		}
	};

	const mainActionHandler = (e) => {
		userForm.user = e.value;
		setSelectClass('ddlPeriod isFilled');
	};

	return (
		<Fragment>
			<ImportModal ref={ImportModalRef} />
			<Modal
				open={isModalVisible}
				className="userprofile"
				footer={null}
				closable={false}
				getContainer={false}
				centered
			>
				<div className="contentinto">
					<span className="title">Change User Profile</span>
					<span className="labelinfo">Select Profile</span>
					<div className="inputwrap">
						<input
							type="text"
							className="txbFeedback searchval"
							placeholder="Search"
							value={userForm.searchval}
							onChange={(e) => inputChangeHandler(e)}
							onKeyDown={(e) => searchEnter(e)}
						/>
						<img
							className="image"
							onClick={() => searchUser()}
							alt="Back"
							src={ICONsearch}
						/>
					</div>
					{/* <span className="errInput">Select Profile can't be empty.</span> */}
					{userData.length ? (
						<div className="inputwrap">
							<DropdownList
								disabled={userData.length === 0}
								options={userData}
								className={selectClass}
								placeholder="Select User"
								menuClassName="options"
								onChange={(event) => mainActionHandler(event)}
								controlClassName="lineControl"
								arrowClosed={
									<img className="iconDown" alt="Expand" src={ICONdownGrey} />
								}
								arrowOpen={<img className="iconUp" alt="Expand" src={ICONup} />}
								value={userForm.user}
							/>
						</div>
					) : (
						<Fragment />
					)}
					{userForm.user !== '' ? (
						<button className="loadprofile" onClick={() => loadinguser()}>
							Load Profile
						</button>
					) : (
						<Fragment />
					)}
				</div>
				<div className="closeicon" onClick={handleCancel}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			{/* <div className="changeicon">
				<img className="changeiconimg" alt="" src={changicon} />
				<span className="changeicontext">Change Profile</span>
			</div> */}
			<section className="adminhome">
				<h2>Hello {user.firstName}, welcome back!</h2>
				<div className="maintitle">Your services</div>
				<div className="contentwrap">
					<div
						className="contentitem mr10"
						onClick={() => {
							openModal();
						}}
					>
						<img alt="" className="contenimage" src={Changeprofile} />
						<span className="contentitle">Change Profile</span>
						<span className="conteninfo">
							With the Administrator role, you can access other registered user
							account and gain access to what they can see and do. Simply
							provide their email address.
						</span>
					</div>
					<div
						className="contentitem mt20 ml10"
						onClick={() => {
							history.push('/adminuser');
						}}
					>
						<img alt="" className="contenimage" src={Contentmanagement} />
						<span className="contentitle">System Admin</span>
						<span className="conteninfo">
							The 'System Admin' functionality allows you to add, edit and
							delete another Administrator and to download system log report and
							a list of registered portal users.
						</span>
					</div>
				</div>
				<div className="contentwrap">
					<div
						className="contentitem mr10"
						onClick={() => {
							history.push('/adminannouncement');
						}}
					>
						<img alt="" className="contenimage" src={Contentmanagement} />
						<span className="contentitle">Announcement</span>
					</div>
					<div className="contentitemnohover mr10">&nbsp;</div>
					{/* <div
						className="contentitem mt20 ml10"
						onClick={() => {
							history.push('/admincarousel');
						}}
					>
						<img alt="" className="contenimage" src={Contentmanagement} />
						<span className="contentitle">Carousel</span>
					</div> */}
				</div>
			</section>
		</Fragment>
	);
};

export default AdminHome;
