import React, { useState, useImperativeHandle, useRef } from 'react';
import { Modal } from 'antd';
import '../../../../node_modules/antd/lib/modal/style/index.css';
import { useReactive, useMemoizedFn } from 'ahooks';
import axios from '../../../axios-custom';
import store from '../../../state/store';
import InputFileUpload from '../../InputFileUploadExcel/InputFileUploadExcel';
const { useModel } = store;

const ImportModal = React.forwardRef((props, ref) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;
	const [isModalVisible, setIsModalVisible] = useState(false);

	let fileUploadRef = useRef(null);

	useImperativeHandle(ref, () => ({
		openModel: () => {
			setIsModalVisible(true);
			titleData.sectionen = '';
			titleData.sectionde = '';
			titleData.sectionfr = '';
			titleData.sectiones = '';
			titleData.sectionpt = '';
		},
	}));

	const titleData = useReactive({
		sectionen: '',
		sectionde: '',
		sectionfr: '',
		sectiones: '',
		sectionpt: '',
	});

	const saveTitle = async () => {
		if (fileUploadRef.current && fileUploadRef.current.itattachments.length) {
			//
		}
		// toggleLoading();
		// await axios.post('/feedbackServices/saction/saveSec', temp);
		// toggleLoading();
		// setIsModalVisible(false);
	};

	return (
		<Modal
			open={isModalVisible}
			className="titleModal"
			footer={null}
			closable={false}
			getContainer={false}
			style={{ top: 150 }}
		>
			<div className="title">Import Category Data</div>
			<div className="inputwrap">
				<InputFileUpload ref={fileUploadRef} />
			</div>
			<div className="addbuttongroup">
				<button className="btnSend" onClick={() => saveTitle()}>
					Import
				</button>
				<button
					className="btnCancel"
					style={{ marginLeft: '30px' }}
					onClick={() => setIsModalVisible(false)}
				>
					Close
				</button>
			</div>
		</Modal>
	);
});

export default ImportModal;
